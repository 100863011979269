.embla {
    max-width: 100%;
    height: 100%;
    margin: auto;
    --slide-spacing: 16px;
    --slide-size: 100%;
    --text-body: rgb(54, 49, 61);
    --text-high-contrast-rgb-value: 49, 49, 49
}

.embla__viewport {
    height: 100%;
    overflow: hidden;
}

.embla__container {
    height: 100%;
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
    height: 100%;
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
}

.embla__slide > img {
    height: 100%;
    width: 100%;
    padding: 0 32px;
    object-fit: contain;
}

.embla__button {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0 4px;
    width: 20px;
    height: 20px;
    z-index: 1000;
    border-radius: 50%;
    color: var(--text-body);
    align-items: center;
    justify-content: center;
}

.embla__button__svg {
    width: 20px;
    height: 20px;
}